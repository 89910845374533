import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import { companyMessageContext } from 'application/contexts/useCompanyMessage';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyStatsQuery } from 'interfaceAdapter/queries/CompanyStats';
import { companyStatsContext } from 'application/contexts/useCompanyStats';
import { CompanyChatRoomQuery } from 'interfaceAdapter/queries/CompanyChatRoom';
import { CompanyUserCheckToken } from 'application/usecases/CompanyUserCheckToken';
import { CompanyQuery } from 'interfaceAdapter/queries/Company';
import { companyAppContext } from 'application/contexts/useCompanyApp';
import { REGISTRATION_STEPS } from 'domain/entities/CompanyUser/CompanyUser';

/**
 * 認証状態の確認と、ログイン後ページの共通処理
 */
export const useCompanyStandard = () => {
  const mainRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const diContainer = useContext(diContainerContext);
  const companyUserCheckToken = diContainer.resolve(CompanyUserCheckToken);
  const companyQuery = diContainer.resolve(CompanyQuery);
  const companyStatsQuery = diContainer.resolve(CompanyStatsQuery);
  const companyChatRoomQuery = diContainer.resolve(CompanyChatRoomQuery);
  const { setUser } = useContext(companyUserContext);
  const { isSideOpen, setIsSideOpen, setPageVisibility } = useContext(companyAppContext);
  const { fetchStats } = useContext(companyStatsContext);
  const { fetchUnreadIds } = useContext(companyMessageContext);
  const [canEnter, setCanEnter] = useState(false);

  useEffect(() => {
    const initialFetch = async () => {
      const result = await companyUserCheckToken.execute(true);
      if (result === false) {
        return;
      }

      // ユーザー登録ステップが完了していない場合はリダイレクト
      if (result.registration_step === REGISTRATION_STEPS.NEED_USER_REGISTRATION_STEP) {
        return navigate('/company/register/user/profile/');
      }
      if (result.registration_step === REGISTRATION_STEPS.NEED_COMPANY_REGISTRATION_STEP) {
        return navigate('/company/register/company/profile/');
      }
      if (result.registration_step === REGISTRATION_STEPS.NEED_REGISTRATION_CONFIRMATION) {
        return navigate('/company/register/confirm_profile/');
      }

      setUser(result);
      fetchStats(() => companyStatsQuery.get());
      fetchUnreadIds(() => companyChatRoomQuery.getUnreadChatRoomIds());

      const isContractViewable = await companyQuery.isContractViewable();
      setPageVisibility({ companyContract: isContractViewable });
    };
    initialFetch();
  }, []);

  useEffect(() => {
    const checkCanEnter = async () => {
      const result = await companyUserCheckToken.execute(true);
      if (result !== false) {
        setCanEnter(true);
      } else {
        // 有効なトークンが無いのでリダイレクト
        navigate('/company/');
      }
    };
    checkCanEnter();

    // ページ遷移時にスクロール位置をトップに戻す
    mainRef.current?.scrollTo(0, 0);
  }, [location]);

  return { mainRef, canEnter, isSideOpen, setIsSideOpen };
};
